@use '../util/' as u;

.topnav {
  background-color: var(--navbar-color-bg);
  position: sticky;
  top: 0;
  z-index: 1000;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-block: u.rem(10);

    @include u.breakpoint-down(medium) {
      flex-wrap: wrap;
      justify-content: center;
      gap: u.rem(10);
    }

    @include u.breakpoint-down(small) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__leftcolumn {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  &__logo {
    max-width: u.rem(280);
    width: 11.25rem;
    width: clamp(11.25rem, 9.049295774647888rem + 9.389671361502346vw, 17.5rem);
    height: auto;
    margin-block-end: 0;
    display: block;
    fill: var(--dark-color-opacity);
    transition: fill 2s ease-in-out;
    margin-inline-start: u.rem(20);

    &:hover {
      fill: var(--primary-button-bg-hover);
    }

    @include u.breakpoint(medium) {
      padding-inline-end: u.rem(45);
    }
  }

  &__rightcolumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    @include u.breakpoint(medium) {
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    background-color: var(--navbar-color-bg);
    width: auto;
    height: u.rem(36);

    @include u.breakpoint(medium) {
      height: u.rem(32);
      justify-content: space-between;
    }

    @include u.breakpoint(large) {
      li {
      }
    }
  }

  &__item {
    display: flex;
  }

  a,
  a:visited {
    color: var(--text-color-light);
  }

  &__link {
    display: none;
    transition: color 2s ease;

    @include u.breakpoint(medium) {
      display: block;
      // font-size: u.rem(16);
      font-size: 0.625rem;
      font-size: clamp(
        0.625rem,
        0.49295774647887325rem + 0.5633802816901408vw,
        1rem
      );
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
      text-align: center;
      font-weight: 700;
    }

    &:hover {
      transition: opacity 0.5s all ease-in-out;
      color: var(--topnav-link-hover);
    }
  }

  &__hamburger {
    width: 30px;
    margin-left: 5px;
    cursor: pointer;

    @include u.breakpoint(medium) {
      display: none;
    }
  }

  &__hamburgerwrapper {
    height: 100%;
    margin-inline-end: u.rem(20);
    cursor: pointer;

    @include u.breakpoint(medium) {
      display: none;
    }
  }
}
