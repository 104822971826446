@use '../util/' as u;

h1,
h2,
h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  max-width: 90ch;
}

h1 {
  font-size: u.rem(25);
  font-size: clamp(u.rem(25), 1.45rem + 0.47vw, u.rem(30));
  line-height: 1;
}

h2 {
  font-size: u.rem(20);
  font-size: clamp(u.rem(20), 1.14rem + 0.47vw, u.rem(30));
}

h3 {
  font-size: u.rem(20);
  font-size: clamp(u.rem(20), 1.14rem + 0.47vw, u.rem(30));
}

p {
  margin: 0;
  font-size: u.rem(14);
  font-size: clamp(u.rem(14), 0.84rem + 0.19vw, u.rem(16));
  max-width: 90ch;
}

a,
a:visited,
a:active {
  text-decoration: none;
}
