@use '../util/' as u;

.shop {
  &__shopContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    gap: u.rem(30);

    @include u.breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: u.rem(20);
    }
  }

  &__itemContainer {
    background-color: var(--shopcard-bg);
    border-radius: u.rem(25);
    padding: u.rem(25);
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;
    width: 100%;

    @include u.breakpoint(medium) {
      width: calc(50% - u.rem(10));
    }

    @include u.breakpoint(large) {
      width: calc(33.33% - u.rem(15));
    }

    &__itemName {
      color: var(--text-color-light);
    }

    &__image {
      width: 100%;

      border-radius: u.rem(25);
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }

    &__description {
      padding-block: u.rem(20);
      p {
        color: var(--text-color-light);
      }
    }

    &__price {
      padding-block-end: u.rem(10);
      color: var(--shop-price);
    }
  }
}
